import './App.css';
import FileUploadScreen from './components/FileUploadScreen';

function App() {
  return (
    <div className="App">
      <FileUploadScreen/>
    </div>
  );
}

export default App;

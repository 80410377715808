import React, { useState, useEffect } from 'react'
import { mainUrl } from './url'
import axios from 'axios'
import Box from '@mui/material/Box';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import { TextField, MenuItem, Select } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import moment from 'moment';
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css'

function FileUploadScreen() {

	const [audios, setAudios] = useState(null)
	const getAudio = (e) => {
		setAudios(e.target.files)
	}
	const [avatar, setAvatar] = useState(null)
	const getAvatar = (e) => {
		setAvatar(e.target.files[0])
	}
	const [albumImg, setAlbumImg] = useState(null)
	const getAlbumImg = (e) => {
		setAlbumImg(e.target.files[0])
	}
	const [artistOne, setArtistOne] = useState(null)
	const [cartistOne, setCartistOne] = useState(null)
	const selectionChangeHandlerOne = (event) => {
		setCartistOne(event.target.value);
	};
	const [artistTwo, setArtistTwo] = useState(null)
	const [cartistTwo, setCartistTwo] = useState(null)
	const selectionChangeHandlerTwo = (event) => {
		setCartistTwo(event.target.value);
	};
	const [artistThree, setArtistThree] = useState(null)
	const [cartistThree, setCartistThree] = useState(null)
	const selectionChangeHandlerThree = (event) => {
		setCartistThree(event.target.value);
	};
	const [calbum, setCalbum] = useState(null)
	const selectionChangeHandlerAlbum = (event) => {
		setCalbum(event.target.value);
	};
	
	const [album, setAlbum] = useState(null)
	const [percentage, setPercentage] = useState(0)
	const uploadProgress = (progressEvent) => {
		const {loaded, total} = progressEvent;
		const percentage = Math.floor(((loaded / 1000) * 100) / (total / 1000));
		setPercentage((prevState)=> prevState <= percentage?percentage:prevState);
	};

	const [mesTitle, setMesTitle] = useState(null)
	const [mesDes, setMesDes] = useState(null)

	const current = new Date().getTime().toString()

	async function getDuration(file) {
		const url = URL.createObjectURL(file);
	   
		return new Promise((resolve) => {
		  const audio = document.createElement("audio");
		  audio.muted = true;
		  const source = document.createElement("source");
		  source.src = url; //--> blob URL
		  audio.preload= "metadata";
		  audio.appendChild(source);
		  audio.onloadedmetadata = function(){
			 resolve(audio.duration*1000)
		  };
		});
	}
	let avatarName = {
		name: `hear-it_avatar_${current}.png`,
	};
	const uploadAvatar = async () => {
		if(avatar){
			let avatarUrl = mainUrl+'api.php?op=avatarUpload'
			let formdata = new FormData()
			formdata.append("fileToUpload", avatar, avatarName.name)
			await axios.post(avatarUrl, formdata, {
				headers: {
					'accept': 'application/json',
					"Content-Type": "multipart/form-data",
				},
			})
		}
	}
	let albumName = {
		name: `hear-it_album_${current}.png`,
	};
	const uploadAlbum = async () => {
		if(albumImg){
			let albumUrl = mainUrl+'api.php?op=albumImgUpload'
			let formdata = new FormData()
			let i = {
				name: `hear-it_album_${current}.png`,
			};
			formdata.append("fileToUpload", albumImg, albumName.name)
			await axios.post(albumUrl, formdata, {
				headers: {
					'accept': 'application/json',
					"Content-Type": "multipart/form-data",
				},
			})
		}
	}
	
	const [allAudio, setAllAduio] = useState(null)
	useEffect(() => {
		let isSubscribed = true;
        if(isSubscribed){
            (async function(){
                try{
                    await axios.get(mainUrl+'api.php?op=audioall')
                    .then(response => {
                        setAllAduio(response['data']['data']['result'])
                    })
                } catch (error) {
                    
                }
            })()
        }
		return () => {isSubscribed = false}
    }, [])

	const [checkArtist, setCheckArtist] = useState(null)
	useEffect(() => {
		var filteredArray = allAudio?.filter(function(item){
			return item.author === cartistOne
		});
		setCheckArtist(filteredArray)
	},[cartistOne])
	const [checkAlbum, setCheckAlbum] = useState(null)
	useEffect(() => {
		var filteredArray = allAudio?.filter(function(item){
			return item.album === calbum
		});
		setCheckAlbum(filteredArray)
	},[calbum])
	
	var authorArr = new Array({value:'null', label:'No selected'})
	var albumArr = new Array({value:'null', label:'No selected'})
	const authorList = Array.from(new Set(allAudio?.map(({author}) => author)))
	authorList.map((author) => {
		authorArr.push({value:author, label:author})
	})
	const albumList = Array.from(new Set(allAudio?.map(({album}) => album)))
	albumList.map((album) => {
		albumArr.push({value:album, label:album})
	})
	var newArtist = artistOne?artistOne:cartistOne
	var newAvatar = avatar?avatarName.name:checkArtist?.[0]?.img
	var newAlbum = album?album:calbum
	var newAlbumImg = albumImg?albumName.name:checkAlbum?.[0]?.album_img
	const uploadAudios = async() => {
		uploadAvatar()
		uploadAlbum()
		setPercentage(0)
		if(Array.from(audios).length > 0) {
			let fileUrl = mainUrl+'api.php?op=filesUpload';
			let uploadUrl = mainUrl+'api.php?op=uploadAudio';
			let formdata = new FormData();
			Array.from(audios).forEach(async(audio, index) => {
				
				const duration = await getDuration(audio)
				let i = {
					size: audio.size,
					name: `hear-it_${index}_${current}.mp3`,
					oName: audio.name,
					artist: newArtist
				};
				formdata.append("fileToUpload", audio, i.name);
				formdata.append('artist', i.artist)
				await axios.post(fileUrl, formdata, {
					headers: {
					'accept': 'application/json',
					"Content-Type": "multipart/form-data",
					},
					onUploadProgress:(p) => uploadProgress(p)
				}).then(async() => {
					/*
					let data = {
						title:i.name,
						author: 'fokker',
						img:'hello',
						uri:i.name,
						duration:duration,
						album:'hello',
						albumImg:'hello',
						date:'date'
					}
					let form = new FormData()
					form.append('data', data)
					await axios.post(uploadUrl, form).then(() => {
						console.log('success')
					})
					*/
					await fetch(uploadUrl, {
						method:'post',
						headers:{
							'Content-Type':'application/x-www-form-urlencoded'
						},
						//body:"title="+i.oName+"&author="+artist?artist:cartist+"&img="+avatar?avatarName.name:checkArtist?.[0]?.img+"&uri="+i.name+"&duration="+duration+"&album="+album?album:calbum+"&albumImg="+albumImg?albumName.name:checkAlbum?.[0]?.album_img+"&size="+i.size+"&date="+moment().toJSON()
						body:"type=music&title="+i.oName?.split('.')?.[0]+"&author="+newArtist+"&img="+newAvatar+"&uri="+i.name+"&duration="+duration+"&album="+newAlbum+"&albumImg="+newAlbumImg+"&like=null&size="+i.size+"&date="+moment().toJSON()
					}).then(() => {
						setAvatar(null)
						setAlbum(null)
						setCalbum(null)
						setAudios(null)
						setArtistOne(null)
						setArtistTwo(null)
						setArtistThree(null)
						setAlbumImg(null)
					})
				}).catch((error) => {
					console.log(error)
				})
			})
		}
	}
	const uploadMessage = async() => {
		let messageUrl = mainUrl+'api.php?op=uploadMessage';
		await fetch(messageUrl, {
			method:'post',
			headers:{
				'Content-Type':'application/x-www-form-urlencoded'
			},
			body:"title="+mesTitle+"&des="+mesDes+"&date="+moment().toJSON()
		}).then(() => {
			setMesDes('')
			setMesTitle('')
		})
	}
	/*
	if(route?.params?.images && route?.params?.images.length > 0 && user?.[0]?.id){
		let apiUrl = mainUrl+'connection-api.php?op=imagesUpload';
		let formdata = new FormData();
		
		route?.params?.images?.forEach(async(image, index) => {
			let i = {
				uri: image.uri,
				type: "multipart/form-data",
				name: `connection_posts${index}_${current}.png`,
			};
			formdata.append("fileToUpload", i);
			imageArray.push(i.name)
			await axios.post(apiUrl, formdata, {
				headers: {
				'accept': 'application/json',
				"Content-Type": "multipart/form-data",
				},
				onUploadProgress:(p) => uploadProgress(p)
			}).then(() => {
				//setImageArray((prevState) => [...prevState, i.name])
			})
		})
	}*/

	return (
		<div style={{padding:20}}>
			<h2>Audio file upload</h2>
			<Box
			component="form"
			sx={{
				'& > :not(style)': { m: 1 },
			}}
			noValidate
			autoComplete="off"
			style={{display:'flex', flexDirection:'column', alignItems:'center'}}
			>	
				<FormControl>
				<InputLabel style={{backgroundColor:'#fff', padding:'2px 5px'}}>1. Choose artist</InputLabel>
				<Select value={cartistOne}
				onChange={selectionChangeHandlerOne}
				style={{width:200}}
				>
				{authorArr.map((option, index) => (
					
					<MenuItem key={option.value} value={option.value} selected={index==0}>
						{option.label}
					</MenuItem>
				))}
				</Select>
				<FormHelperText>1. Select a artist</FormHelperText>
				</FormControl>
				<FormControl variant="standard" style={{width:200}}>
					<InputLabel htmlFor="component-simple">1. Artist Name</InputLabel>
					<Input id="component-simple" value={artistOne} onChange={event => setArtistOne(event.target.value)} 
					disabled={cartistOne && cartistOne !== 'null'}
					/>
				</FormControl>
				<FormControl variant="standard" style={{width:200}}>
					<Input type='file' id="component-simple" onChange={(e) => getAvatar(e)} 
					disabled={cartistOne && cartistOne !== 'null'}
					/>
				</FormControl>

				<FormControl>
					<InputLabel style={{backgroundColor:'#fff', padding:'2px 5px'}}>Choose album</InputLabel>
					<Select value={calbum}
					onChange={selectionChangeHandlerAlbum}
					style={{width:200}}
					>
					{albumArr.map((option, index) => (
						
						<MenuItem key={option.value} value={option.value} selected={index==0}>
							{option.label}
						</MenuItem>
					))}
					</Select>
					<FormHelperText>Select a album</FormHelperText>
				</FormControl>
				<FormControl variant="standard" style={{width:200}}>
					<InputLabel htmlFor="component-simple">Album Name</InputLabel>
					<Input id="component-simple" value={album} onChange={event => setAlbum(event.target.value)} 
					disabled={calbum && calbum !== 'null'}
					/>
				</FormControl>
				<FormControl variant="standard" style={{width:200}}>
					<Input type='file' id="component-simple" onChange={(e) => getAlbumImg(e)} 
					disabled={calbum && calbum !== 'null'}
					/>
				</FormControl>
				<h2>Choose Audio files</h2>
				<FormControl variant="standard" style={{width:200}}>
					<Input type="file" id="component-simple" inputProps={{multiple: true}} onChange={(e) => getAudio(e)} />
				</FormControl>
				<Button variant="contained" endIcon={<SendIcon />}
				onClick={uploadAudios}
				>
					Send
				</Button>
				<div>
					<CircularProgressbar
						value={percentage}
						text={`${percentage}%`}
						styles={
							buildStyles({
								rotation:0.25,
								strokeLinecap:'butt',
								textSize:'16px',
								pathTransitionDuration:0.5,
								pathColor:`rgba(255,136,136, ${percentage/100})`,
								textColor:'#f88',
								trailColor:'#d6d6d6',
								backgroundColor:'#3e98c7'
							})
						}
					/>
				</div>
			</Box>
			<Box
			component="form"
			sx={{
				'& > :not(style)': { m: 1 },
			}}
			noValidate
			autoComplete="off"
			style={{display:'flex', flexDirection:'column', alignItems:'center'}}
			>
				<h1>Message Upload</h1>
				<FormControl variant="standard" style={{width:400}}>
					<InputLabel htmlFor="component-simple">1. Title</InputLabel>
					<Input id="component-simple" value={mesTitle} onChange={event => setMesTitle(event.target.value)} 
					/>
				</FormControl>
				<FormControl variant="standard" style={{width:'80%'}}>
					<TextField 
					placeholder='Description'
					id="component-simple" value={mesDes} onChange={event => setMesDes(event.target.value)} 
					multiline
					rows={4}
					/>
				</FormControl>
				<Button variant="contained" endIcon={<SendIcon />}
				onClick={uploadMessage}
				>
					Send
				</Button>
			</Box>
		</div>
	)
}

export default FileUploadScreen

/*
{
						const {loaded, total} = p;
						const percentage = Math.floor(((loaded / 1000) * 100) / (total / 1000));
						console.log(percentage)
					}
*/